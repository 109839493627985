import React from 'react';
import styled, { css } from 'styled-components';

function Footer() {
  return (
    <Container>
      <Text>
        이용 관련 문의사항이 있으신 경우, 아래 연락처로 문의바랍니다.
        <br />
        T. 02-6275-8011
      </Text>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 80px;
  padding: 22px 40px 22px 40px;
  border-top: 1px solid ${(props) => props.theme.mono[10]};
`;

const Text = styled.p`
  color: ${(props) => props.theme.mono[50]};
`;

export default Footer;
