import React, { useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Refund from '@/pages/Refund';
import RefundDetail from '@/pages/RefundDetail';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import Login from '@/pages/Login';
import { loginUserState, updateUserInfo } from '@/store/modules/user';
import Home from '@/pages/Home';
import Medical from '@/pages/Medical';
import Notice from '@/pages/Notice';
import styled from 'styled-components';
import NoticeDetail from '@/components/notice/NoticeDetail';
import RefundStatement from '@/pages/RefundStatement';
import NotPage from '@/pages/NotPage';
import Redirect from '@/components/common/Redirect';
import KioskSetting from '@/pages/KioskSetting';
import AutoLogin from '@/pages/AutoLogin';
import SideNavigation from '@/components/sideNavigation';
import RefundDeparture from '@/pages/RefundDeparture';

const RouterContainer = () => {
  const { isLogged, hospitals } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (localStorage.getItem('loginToken')) {
      dispatch(loginUserState(true));
    }
    // 초기값이 없을시 빈배열로 초기화
    if (!hospitals) {
      dispatch(updateUserInfo({ hospitals: [] }));
    }
  }, []);

  return (
    <Router>
      <Redirect />
      <Wrapper className='notranslate'>
        {isMobile ? (
          <NotPage />
        ) : (
          <>
            {isLogged && <SideNavigation />}
            {isLogged ? (
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/refund' element={<Refund />} />
                <Route path='/refund-detail' element={<RefundDetail />} />
                <Route path='/refund-statement' element={<RefundStatement />} />
                <Route path='/refund-departure' element={<RefundDeparture />} />
                <Route path='/medical-info' element={<Medical />} />
                <Route path='/home/notice' element={<Notice />}></Route>
                <Route path='/home/notice/detail' element={<NoticeDetail />} />
                <Route path='/kiosk' element={<KioskSetting />} />
                <Route path='/auto-login' element={<AutoLogin />} />
              </Routes>
            ) : (
              <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/auto-login' element={<AutoLogin />} />
              </Routes>
            )}
          </>
        )}
      </Wrapper>
    </Router>
  );
};

const Wrapper = styled.div`
  display: flex;
  max-width: 100vw;
`;

export default RouterContainer;
