import { Button, Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  link: string;
};

function PassportCheckModal({ isOpen, onClose, link }: Props) {
  return (
    <Modal isOpen={isOpen} hasBackdrop>
      <Modal.Header>
        <Title>페이지를 나가시겠습니까?</Title>
      </Modal.Header>
      <Content>
        하단 ‘환급하기’ 버튼을 눌러주셔야 정상적으로 환급 처리가 됩니다.
        <br />
        페이지 이동시 입력된 내용은 모두 초기화됩니다.
      </Content>
      <Modal.Footer>
        <Modal.Button variant='cancel' onClick={onClose}>
          계속 입력
        </Modal.Button>
        <Button
          onClick={() => {
            window.location.href = link;
            onClose();
          }}
          style={{ backgroundColor: '#FF2D55' }}
        >
          나가기
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const Title = styled.h4`
  font-size: 21px;
  font-weight: 700;
  color: #030303;
  margin-bottom: 16px;
`;

const Content = styled.p`
  width: 436px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #5f6165;
`;

export default PassportCheckModal;
