import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styled from 'styled-components';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

import Calendar from '@/assets/refund/calendar.png';
import ActionBtn from '@/components/common/ActionBtn';
import { setFilterSeletor } from '@/store/modules/refund';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { defaultStaticRanges } from '@/constants/dateRange';

type DateSelectorFilterProps = {
  isRefundDetail?: boolean;
  setPageNumber?: Dispatch<SetStateAction<number>>;
};

function DateSelectorFilter({
  isRefundDetail,
  setPageNumber,
}: DateSelectorFilterProps) {
  const { filter } = useAppSelector((state) => state.refund);

  const dispatch = useAppDispatch();
  const [date, setDate] = useState({
    startDate: filter.startDate,
    endDate: filter.endDate,
  });
  const { startDate, endDate } = date;
  const [isActiveCalendar, setIsActiveCalendar] = useState(false);

  // 환급내역 페이지면 초기값: 한달전 ~ 오늘날
  useEffect(() => {
    if (isRefundDetail) {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);
      setDate((prev) => ({ ...prev, startDate: startDate }));
      dispatch(
        setFilterSeletor({
          startDate,
          endDate,
        }),
      );
    }
  }, []);

  const onChange = (e: any) => {
    const { startDate, endDate } = e.selection;
    setDate({ startDate, endDate });
  };
  const onApply = (e: any) => {
    dispatch(
      setFilterSeletor({
        startDate,
        endDate,
      }),
    );
    onCloseCalendar(e);
    setPageNumber && setPageNumber(0);
  };
  const onCancel = (e: any) => {
    setDate({
      startDate: filter.startDate,
      endDate: filter.endDate,
    });
    onCloseCalendar(e);
  };
  const onCloseCalendar = (e: any) => {
    if (e.target === e.currentTarget) {
      setIsActiveCalendar(false);
    }
  };

  return (
    <>
      <DateSelector onClick={() => setIsActiveCalendar(true)}>
        <Icon src={Calendar} />
        {`${format(new Date(startDate), 'yyyy-MM-dd ~ ')}${format(
          new Date(endDate),
          'yyyy-MM-dd',
        )}`}
      </DateSelector>
      {isActiveCalendar && (
        <Container onClick={onCloseCalendar}>
          <CalendarModal>
            <DateRangePicker
              ranges={[
                {
                  startDate,
                  endDate,
                  key: 'selection',
                },
              ]}
              onChange={onChange}
              locale={ko}
              months={2}
              direction='horizontal'
              staticRanges={defaultStaticRanges}
              inputRanges={[]}
            />
            <ActionBtn
              title='취소'
              bgColor='white'
              color='gray'
              outlineColor='gray'
              onClick={onCancel}
              right='130px'
              bottom='16px'
            />
            <ActionBtn
              title='적용'
              onClick={onApply}
              right='20px'
              bottom='16px'
            />
          </CalendarModal>
        </Container>
      )}
    </>
  );
}
const Container = styled.div`
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`;
const Icon = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 8px;
  top: 4px;
`;
const DateSelector = styled.button`
  position: relative;
  padding: 4px 20px 4px 36px;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  color: #212121;
`;
const CalendarModal = styled.div`
  position: absolute;
  z-index: 5px;
  right: 60px;
  top: 100px;
  background-color: #ffffff;
  padding-bottom: 40px;
`;

export default memo(DateSelectorFilter);
