import { comma } from '@/util/format';
import { Button, Dropdown, Icon } from '@successmode-ktp/kui';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import TableHeader from '../refund/PaymentComplete/TableHeader';
import refundNotImage from '@/assets/refund/refundNot.png';
import RefundDetailModal from './RefundDetailModal';
import {
  refundDeparture4MonthsResponse,
  refundDepartureDateResponse,
  refundDepartureSummaryResponse,
} from '@/types/refund';
import {
  getLatestYear,
  getPreviousDate,
  monthData,
  yearData,
} from '@/constants/dateRange';
import { format } from 'date-fns';
import {
  getRefundDeparture4Months,
  getRefundDepartureDate,
  getRefundDepartureSummary,
} from '@/api/refund';
import { useQuery } from 'react-query';
import { RefundDepartureListHeader } from '@/constants/refund';
import DataDivision from './DataDivision';

function RefundDepartureContent() {
  const { data: departureData } = useQuery<refundDepartureDateResponse>(
    ['refundDepartureDate'],
    getRefundDepartureDate,
  );

  const [currentYear, setCurrentYear] = useState<string | number>('');
  const [currentMonth, setCurrentMonth] = useState<string | number>('');

  const { data: summaryData } = useQuery<refundDepartureSummaryResponse>(
    ['refundDepartureSummary', currentYear, currentMonth],
    () =>
      getRefundDepartureSummary({
        date: `${currentYear}-${currentMonth}-01`,
      }),
    {
      enabled: !!currentYear && !!currentMonth,
      keepPreviousData: true,
    },
  );
  const { data: fourMonthsData } = useQuery<refundDeparture4MonthsResponse>(
    ['refundDeparture4Months', currentYear, currentMonth],
    () =>
      getRefundDeparture4Months({
        date: `${currentYear}-${currentMonth}-01`,
      }),
    {
      enabled: !!currentYear && !!currentMonth,
      keepPreviousData: true,
    },
  );

  const [isOpenDetail, setIsOpenDetail] = useState<string | null | undefined>(
    undefined,
  );

  const onCloseDetail = () => {
    setIsOpenDetail(undefined);
  };

  const RefundData = [
    {
      label: '외국인 환자 택스리펀 건',
      value: summaryData?.count || 0,
      unit: '건',
      icon: <Icon.My fillColor='#5089F8' />,
    },
    {
      label: '출국 완료',
      value: summaryData?.departureCompleteCount || 0,
      unit: '건',
      tooltip: '출국 완료 시점으로 부가세 신고에 반영됩니다.',
    },
    {
      label: '출국 확인중',
      value: summaryData?.departureNotCompleteCount || 0,
      unit: '건',
      tooltip: (
        <>
          시술 후 3개월 이내에 출국이 확인되면
          <br />
          [출국 완료] 건으로 반영 됩니다.
        </>
      ),
    },
    {
      label: '환급액',
      value: summaryData?.totalRefund || 0,
      unit: '원',
      icon: <Icon.Refund fillColor='#5089F8' size={28} />,
    },
  ];

  const yearOptions = useMemo(() => {
    if (departureData) {
      const startYear = new Date(departureData.startDate).getFullYear();
      const endYear = new Date(departureData.endDate).getFullYear();

      const yearList = getLatestYear(startYear, endYear);

      return yearList.filter((year) => Number(year.value) >= 2024);
    }

    return yearData;
  }, [departureData]);

  const monthOptions = useMemo(() => {
    let monthList = [...monthData];

    if (currentYear === '2024') {
      monthList = monthList.filter((month) => Number(month.value) >= 10);
    }

    if (departureData) {
      if (
        Number(currentYear) === new Date(departureData.startDate).getFullYear()
      ) {
        monthList = monthList.filter(
          (month) =>
            Number(month.value) >=
            new Date(departureData.startDate).getMonth() + 1,
        );

        monthList = monthList.filter(
          (month) => Number(month.value) <= Number(currentMonth),
        );
      }
    }

    return monthList;
  }, [departureData, currentYear]);

  useEffect(() => {
    if (departureData) {
      const { year, month } = getPreviousDate(new Date());
      setCurrentYear(year.toString());
      setCurrentMonth(monthData[month - 1].value);
    }
  }, [departureData]);

  return (
    <>
      <Container>
        <Section>
          <DateSelection>
            <Dropdown
              options={yearOptions}
              selectedValue={currentYear}
              setSelectedValue={setCurrentYear}
              style={{ width: '112px' }}
            />
            <Dropdown
              options={monthOptions}
              selectedValue={currentMonth}
              setSelectedValue={setCurrentMonth}
              style={{ width: '112px' }}
            />
          </DateSelection>
          <DataDivision data={RefundData} />
          <Table>
            <TableHeader titleList={RefundDepartureListHeader} hasNotOption />
            {fourMonthsData?.summaries &&
            fourMonthsData?.summaries?.length > 0 ? (
              <TableList>
                {fourMonthsData.summaries.map((data) => (
                  <Item
                    key={data.date}
                    isActive={
                      departureData && data.date
                        ? format(new Date(data.date), 'MM') === currentMonth
                        : false
                    }
                  >
                    <Content flex={1}>
                      {data.date
                        ? Number(format(new Date(data.date), 'MM')) + '월 출국'
                        : '출국 확인중'}
                    </Content>
                    <Content flex={1}>{comma(data.count)}건</Content>
                    <Content flex={1}>{comma(data.totalRefund)}원</Content>
                    <Content flex={1}>
                      <Button
                        size='sm'
                        enabled
                        style={{ width: 'auto', padding: '12px' }}
                        onClick={() =>
                          departureData && setIsOpenDetail(data.date)
                        }
                      >
                        상세보기
                      </Button>
                    </Content>
                  </Item>
                ))}
              </TableList>
            ) : (
              <RefundNotContainer>
                <Image src={refundNotImage} />
                <Text>검색 결과가 없습니다.</Text>
              </RefundNotContainer>
            )}
          </Table>
        </Section>
      </Container>
      {isOpenDetail !== undefined && (
        <RefundDetailModal
          date={`${currentYear}-${currentMonth}-01`}
          detailDate={isOpenDetail}
          isOpen={!!(isOpenDetail !== undefined)}
          onClose={onCloseDetail}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 22px 40px 85px 40px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 36px;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background-color: #fff;
`;

const DateSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const Table = styled.div`
  width: 100%;
`;

const TableList = styled.ul``;

const Item = styled.li<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.mono[10]};
  background-color: #fff;
  ${({ isActive = false }) =>
    isActive
      ? css`
          font-weight: 700;
          color: #3a3b3e;
          height: 120px;
        `
      : css`
          font-weight: 400;
          color: #80848a;
        `}

  > div:first-child {
    color: ${({ isActive = false }) => (isActive ? '#246CF6' : '#80848a')};
  }
`;

const Content = styled.div<{
  flex: number;
}>`
  display: flex;
  flex: ${(props) => props.flex};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 24px;
`;

const RefundNotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1;
  padding: 24px 0;
`;

const Image = styled.img`
  width: 72px;
  height: 72px;
`;

const Text = styled.span`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[50]};
`;

export default RefundDepartureContent;
