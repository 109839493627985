import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import resetActiveIcon from '@/assets/common/reset_active.png';
import resetIcon from '@/assets/common/reset.png';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@/components/common/CheckBox';

interface IProps {
  index: number;
  onReset: () => void;
}

function SupplyInputBtn({ index, onReset }: IProps) {
  const [isActive, setIsActive] = useState(false);
  const [isCheck, setIsCheck] = useState(false);

  const methods = useFormContext();
  const watchField = methods.watch([
    `supply.${index}.price`,
    `supply.${index}.productType`,
  ]);

  useEffect(() => {
    if (
      methods.getValues(`supply.${index}.price`) ||
      methods.getValues(`supply.${index}.productType`)
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [watchField]);

  useEffect(() => {
    methods.setValue(`supply.${index}.isCheck`, isCheck);
  }, [isCheck]);

  return (
    <Item flex={1.5}>
      <ResetButton isActive={isActive} onClick={onReset}>
        초기화
        <Image src={isActive ? resetActiveIcon : resetIcon} />
      </ResetButton>
    </Item>
  );
}

const Item = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: ${(props) => props.flex};
  height: 52px;
`;

const Image = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  @media ${(props) => props.theme.laptop} {
    display: none;
  }
`;

const ResetButton = styled.button<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 5px 16px;
  ${(props) => (props.isActive ? props.theme.mono[80] : props.theme.mono[20])};
  color: ${(props) =>
    props.isActive ? props.theme.mono[80] : props.theme.mono[20]};
`;

export default SupplyInputBtn;
