import React from 'react';
import styled from 'styled-components';
import FlexWrap from '../common/FlexWrap';
import Error from '../../assets/common/error.png';
import Confirm from '../../assets/common/confirm.png';
import { format } from 'date-fns';

type Props = {
  result: 'success' | 'error';
  onClose: () => void;
};

function CashBillResultModal({ result, onClose }: Props) {
  return (
    <BackDrop>
      <Container dir='column' alignItems='center'>
        <TitleWrapper>
          <img
            src={result === 'success' ? Confirm : Error}
            alt=''
            width={26}
            height={26}
          />
          <Title>
            {result === 'success'
              ? '현금영수증 자동 발행 동의 완료'
              : '오류 발생'}
          </Title>
        </TitleWrapper>
        <Content>
          {result === 'success'
            ? `${format(
                new Date(),
                'yyyy년 MM월 dd일',
              )}부로 현금영수증 자동 발행 동의가 완료되었습니다.`
            : 'KTP 고객센터로 연락주세요. (02-6275-8011)'}
        </Content>
        <ConfirmButton type='button' onClick={onClose}>
          확인
        </ConfirmButton>
      </Container>
    </BackDrop>
  );
}

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(33, 33, 33, 0.4);
  z-index: 800;
`;

const Container = styled(FlexWrap)`
  width: 500px;
  border-radius: 12px;
  background-color: #fff;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const Title = styled.h3`
  color: #030303;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const ConfirmButton = styled.button`
  border-radius: 8px;
  background-color: #246cf6;
  color: #fff;
  width: 100%;
  height: 52px;
`;

const Content = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #5f6165;
  width: 100%;
`;

export default CashBillResultModal;
