export const routes = {
  home: '/',
  refund: '/refund',
  refundDetail: '/refund-detail',
  refundStatement: '/refund-statement',
  refundDeparture: '/refund-departure',
  medicalInfo: '/medical-info',
  notice: '/home/notice',
  noticeDetail: '/home/notice/detail',
  kiosk: '/kiosk',
};
