import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { postLogNCrash } from '@/api/nhn';

function useScanLog() {
  return useMutation<null, AxiosError, string>(
    (payload) => postLogNCrash(payload),
    {
      retry: (failureCount, error) => {
        if (error.message === 'Network Error' && failureCount < 3) {
          return true;
        }
        return false;
      },
    },
  );
}

export default useScanLog;
