import styled, { css } from 'styled-components';

type Props = {
  currentTab?: 'statement' | 'departure';
};

function RefundTab({ currentTab = 'statement' }: Props) {
  const TABS = [
    {
      value: 'statement',
      name: '신고자료',
      link: '/refund-statement',
    },
    {
      value: 'departure',
      name: '출국현황',
      link: '/refund-departure',
    },
  ];
  return (
    <TabNav>
      {TABS.map((tab) => (
        <LinkButton
          key={tab.value}
          href={tab.link}
          currentTab={currentTab === tab.value}
        >
          {tab.name}
        </LinkButton>
      ))}
    </TabNav>
  );
}

const TabNav = styled.nav`
  display: flex;
  margin: 40px 0 0 0;
  padding: 0 40px;
`;

const LinkButton = styled.a<{ currentTab: boolean }>`
  padding: 8px 12px;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  ${({ currentTab }) =>
    currentTab
      ? css`
          color: #3a3b3e;
          border-bottom: 3px solid #030303;
        `
      : css`
          color: #cbccce;
          border-bottom: 3px solid #cbccce;
        `}
`;

export default RefundTab;
