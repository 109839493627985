import { IPasswordChange } from '@/components/medicalInfo/PasswordChange';
import {
  AutoSignInPayload,
  IGlobalStatusRes,
  ISigInPayload,
  ISignInResponse,
  IUploadExcelImagePayload,
} from '@/types/user';
import api from '..';

// 로그인
export const login = async (payload: ISigInPayload) => {
  const res = await api.postAxios<ISignInResponse, ISigInPayload, null>(
    '/user/sign-in',
    payload,
  );
  return res.data;
};
// 엑세스 토큰으로 로그인
export const signInWithAccessToken = async (payload: AutoSignInPayload) => {
  const res = await api.postAxios<ISignInResponse, AutoSignInPayload, null>(
    '/user/sign-in/token',
    payload,
  );
  return res.data;
};

// // 엑세스 토큰으로 로그인
// export const signInWithAccessToken = async (payload: AutoSignInPayload) => {
//   const res = await api.postAxios<ISignInResponse, AutoSignInPayload, null>(
//     '/user/sign-in/token',
//     payload
//   );
//   return res.data;
// };

// 로그아웃
export const logOut = async () => {
  const res = await api.deleteAxios<null, null, null>('/user/sign-out');
  return res.data;
};

// 비밀번호 변경
export const passwordChange = async (payload: IPasswordChange) => {
  const res = await api.patchAxios<boolean, IPasswordChange, null>(
    '/user/medical-info/password-change',
    payload,
  );
  return res.data;
};

//의료용역공급확인서 이미지 변환 후 서버에 저장
export const uploadExel = async (payload: IUploadExcelImagePayload) => {
  const res = await api.postAxios<number, FormData, null>(
    '/user/refund/upload',
    payload.formData,
  );
  return res.status;
};

// 전역상태 데이터 조회
export const getGlobalStatus = async () => {
  const res = await api.getAxios<IGlobalStatusRes, null>(`/user/global`);
  return res.data;
};

// 바로빌 회원가입
export const signUpBarobill = async (payload: any) => {
  const res = await api.postAxios<null, null, null>(
    '/barobill/sign-up',
    payload,
  );
  return res.status;
};
