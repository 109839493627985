import { comma } from '@/util/format';
import { Icon } from '@successmode-ktp/kui';
import { ReactNode } from 'react';
import styled from 'styled-components';

type RefundDataType = {
  label: string;
  value: number;
  unit: string;
  icon?: ReactNode;
  tooltip?: ReactNode;
};

type Props = {
  data: RefundDataType[];
};

function DataDivision({ data }: Props) {
  return (
    <DataList>
      {data.map((d) => (
        <DataItem key={d.label}>
          <DataTitle>
            {d.icon}
            <strong>{d.label}</strong>
            {d.tooltip && (
              <ToolTipWrapper>
                <TooltipButton>
                  <Icon.QuestionFilled size={18} fillColor='#CBCCCE' />
                </TooltipButton>
                <Tooltip>{d.tooltip}</Tooltip>
              </ToolTipWrapper>
            )}
          </DataTitle>
          <Data>
            <DataValue>{comma(d.value)}</DataValue>
            <DataUnit>{d.unit}</DataUnit>
          </Data>
        </DataItem>
      ))}
    </DataList>
  );
}

const DataList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  width: 100%;
  border: 1px solid #e5e6e8;
  border-radius: 12px;
`;

const DataItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 100%;
  border-right: 1px solid #e5e6e8;

  &:last-child {
    border-right: 0;
  }
`;

const DataTitle = styled.dt`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: #3a3b3e;
`;

const Data = styled.dd`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 18px;
  font-weight: 700;
`;

const DataValue = styled.span`
  color: #246cf6;
`;

const DataUnit = styled.span`
  color: #5f6165;
`;

const ToolTipWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;

  &:hover {
    aside {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      visibility: visible;

      &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 70px;
        margin-left: -7px;
        border-width: 7px;
        border-style: solid;
        border-color: transparent transparent #000000cc transparent;
      }
    }
  }
`;

const Tooltip = styled.aside`
  position: absolute;
  top: 40px;
  left: -60px;
  display: block;
  background-color: #000000cc;
  color: #ffffff;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  padding: 24px;
  border-radius: 8px;
  text-wrap: nowrap;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -10px, 0);
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out,
    visibility 200ms ease-in-out;
`;

const TooltipButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
`;

export default DataDivision;
