import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import Select from 'react-select';
import { setFilterOrderByRefundStatus } from '@/store/modules/refund';
interface IProps {
  titleList: CardGraph[];
  hasNotOption?: boolean;
}

interface CardGraph {
  id: number;
  title: string;
  flex: number;
}

function TableHeader({ titleList, hasNotOption = false }: IProps) {
  const { hospitals, isKioskStore } = useAppSelector((state) => state.user);
  const { filter } = useAppSelector((state) => state.refund);
  const dispatch = useAppDispatch();

  const refundStatusOptions = [
    { label: '환급상태', value: '' },
    { label: '승인', value: 'OK' },
    { label: '취소', value: 'CANCEL' },
    { label: '송금대기', value: 'TRANSFER_CHECK' },
  ];
  const [orderByRefundStatus, setOrderByRefundStatus] = useState<{
    label: string;
    value: string;
  }>(
    refundStatusOptions.find(
      (option) => option.value === filter.orderByRefundStatus,
    ) || refundStatusOptions[0],
  );

  const cardTitle = (title: string) => {
    const onChangeRefundStatus = (e: any) => {
      setOrderByRefundStatus(e);
      dispatch(setFilterOrderByRefundStatus(e.value));
    };

    if (title === '환급상태') {
      return (
        <Select
          isSearchable={false}
          //@ts-ignore
          defaultValue={orderByRefundStatus}
          value={orderByRefundStatus}
          onChange={onChangeRefundStatus}
          //@ts-ignore
          options={refundStatusOptions}
          styles={{
            container: (baseStyles: any) => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'transparent',
              textAlign: 'center',
            }),
            control: () => ({
              display: 'flex',
              alignItems: 'center',
              minWidth: 100,
              height: 21,
            }),
            indicatorSeparator: () => ({
              width: 0,
            }),
            singleValue: (baseStyles: any) => ({
              ...baseStyles,
              color:
                orderByRefundStatus.value === 'CREATED_DATE'
                  ? '#246CF6'
                  : '#5F6165',
              textOverflow: 'none',
            }),
            dropdownIndicator: (baseStyles: any) => ({
              ...baseStyles,
              color:
                orderByRefundStatus.value === 'CREATED_DATE'
                  ? '#246CF6'
                  : '#5F6165',
              paddingLeft: 0,
            }),
          }}
        />
      );
    }

    return title;
  };

  return (
    <Container>
      {titleList.map((card) => (
        <Fragment key={card.id}>
          {hospitals.length === 0 && card.title === '구분' && !hasNotOption ? (
            <></>
          ) : !isKioskStore &&
            card.title === '키오스크 환급액' &&
            !hasNotOption ? (
            <></>
          ) : (
            <CardTitle
              flex={card.flex}
              isKioskStore={card.title === '키오스크 환급액'}
            >
              {hasNotOption ? card.title : cardTitle(card.title)}
            </CardTitle>
          )}
        </Fragment>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 26px;
  background-color: ${(props) => props.theme.mono[10]};
  border-bottom: 1px solid ${(props) => props.theme.mono[35]};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const CardTitle = styled.h5<{ flex: number; isKioskStore: boolean }>`
  display: flex;
  flex: ${(props) => props.flex};
  justify-content: center;
  padding: 18px 8px;
  font-size: ${(props) => props.theme.fontSize.xSmall};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.xSmall};
  color: ${(props) =>
    props.isKioskStore ? props.theme.blue[200] : props.theme.mono[65]};
  @media ${(props) => props.theme.mediumView} {
    padding: 18px 20px;
  }
`;

export default TableHeader;
