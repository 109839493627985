export const birthMonthList = [
  { label: 'JAN (1월)', value: '01' },
  { label: 'FEB (2월)', value: '02' },
  { label: 'MAR (3월)', value: '03' },
  { label: 'APR (4월)', value: '04' },
  { label: 'MAY (5월)', value: '05' },
  { label: 'JUN (6월)', value: '06' },
  { label: 'JUL (7월)', value: '07' },
  { label: 'AUG (8월)', value: '08' },
  { label: 'SEP (9월)', value: '09' },
  { label: 'OCT (10월)', value: '10' },
  { label: 'NOV (11월)', value: '11' },
  { label: 'DEC (12월)', value: '12' },
];

export const StorageListHeader = [
  { key: 1, title: 'No.', flex: 1 },
  { key: 2, title: '저장일', flex: 2.3 },
  { key: 3, title: '국적', flex: 1.3 },
  { key: 4, title: '고객 성함', flex: 2.5 },
  { key: 5, title: '여권 번호', flex: 2.5 },
  { key: 6, title: '의료 금액', flex: 2.5 },
  { key: 7, title: '삭제', flex: 2 },
  { key: 8, title: '불러오기', flex: 2.5 },
];

export const RefundDepartureListHeader = [
  { id: 1, title: '구분', flex: 1 },
  { id: 2, title: '건수', flex: 1 },
  { id: 3, title: '환급액', flex: 1 },
  { id: 4, title: '', flex: 1 },
];

export const RefundDepartureDetailListHeader = [
  { id: 1, title: 'No.', flex: 0.7 },
  { id: 3, title: '환급상태', flex: 1.8 },
  { id: 4, title: '시술일자', flex: 2 },
  { id: 5, title: '국적', flex: 1.5 },
  { id: 6, title: '고객 성함', flex: 3.5 },
  { id: 8, title: '결제금액', flex: 3 },
  { id: 9, title: '예상 환급액', flex: 2 },
];
