import React from 'react';
import styled from 'styled-components';
import FlexWrap from '../common/FlexWrap';
import { format } from 'date-fns';

type Props = {
  cashBillAgreementDate: string | null;
};

function MedicalCashBill({ cashBillAgreementDate }: Props) {
  return (
    <Container gap='16px' dir='column' flex='1'>
      <Title>환급금 현금영수증 자동 발행</Title>
      <AccountContent>
        <RowContainer>
          <InputContainer>
            <Label>동의 일자</Label>
            <Input
              value={
                cashBillAgreementDate
                  ? format(new Date(cashBillAgreementDate), 'yyyy년 MM월 dd')
                  : '-'
              }
            />
          </InputContainer>
        </RowContainer>
      </AccountContent>
    </Container>
  );
}

const Container = styled(FlexWrap)`
  margin-top: 48px;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
`;

const AccountContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 16px;
  width: 100%;
  background-color: ${(props) => props.theme.pointColors.white};
  border-radius: 16px;
  @media ${(props) => props.theme.largeView} {
    padding: 32px;
  }
`;
const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.mono[80]};
  width: 160px;
  padding-left: 16px;
`;
const RowContainer = styled(FlexWrap)`
  width: 100%;
  gap: 100px;
  @media ${(props) => props.theme.largeView} {
    gap: 120px;
  }
`;
const InputContainer = styled.div<{ address?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
`;

const Input = styled.input.attrs({ disabled: true })`
  width: 100%;
  padding: 13px 10px;
  border-radius: 4px;
  color: ${(props) => props.theme.grayColors.scale2};
  border: none;
  background-color: ${(props) => props.theme.mono[5]};
`;

export default MedicalCashBill;
