import { Popover, Button } from '@successmode-ktp/kui';
import CashbillUpdateImage from '@/assets/Home/barobillUpdate.png';
import styled from 'styled-components';
import WarningIcon from '@/assets/common/error.png';
import { useMutation } from 'react-query';
import { hideModal3Hours } from '@/api/home';
import { useState } from 'react';
import CashBillModal from '../sideNavigation/CashBillModal';
import XIcon from '@/assets/common/close.png';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
};

function CashbillUpdateModal({ isOpen = false, onClose = () => {} }: Props) {
  const [isOpenCashBillModal, setIsOpenCashBillModal] = useState(false);

  const { mutate: mutateHideModal } = useMutation(hideModal3Hours);
  const onHideModal = () => {
    mutateHideModal(
      {},
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  const onOpenAgreementModal = () => {
    setIsOpenCashBillModal(true);
  };

  return (
    <>
      <Popover
        isOpen={isOpen}
        hasBackdrop
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
          padding: '24px',
          width: '700px',
          borderRadius: '12px',
        }}
      >
        <CloseButton onClick={onHideModal}>
          <img src={XIcon} alt='' width={24} />
        </CloseButton>
        <Header>
          <Title>
            <img src={WarningIcon} alt='' width={28} />
            [KTP] 환급액 국세청 집계 업데이트 안내
          </Title>
          <Description>
            2024년 11월 1일부터 환급액 국세청 집계 기능이 업데이트 됩니다.
            <br />
            업데이트 적용을 위해 동의 버튼을 눌러 주시기 바랍니다.
            <br />
            *02-6275-8011 (연결 2번)
          </Description>
        </Header>
        <img src={CashbillUpdateImage} alt='' width='100%' />
        <ButtonWrapper>
          <LinkButton href='/home/notice/detail?id=53'>
            <Button theme='mono' onClick={onHideModal}>
              자세히보기
            </Button>
          </LinkButton>
          <Button onClick={onOpenAgreementModal}>동의하기</Button>
        </ButtonWrapper>
      </Popover>
      {isOpenCashBillModal && (
        <CashBillModal
          onCancel={() => setIsOpenCashBillModal(false)}
          onClose={() => {
            setIsOpenCashBillModal(false);
            onClose();
          }}
        />
      )}
    </>
  );
}

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 21px;
  font-weight: 700;
  color: #3a3b3e;
  margin-bottom: 8px;
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #3a3b3e;
  text-align: center;
  line-height: 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 20px;
`;

const LinkButton = styled.a`
  width: 100%;
`;

export default CashbillUpdateModal;
