import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import logo from '@/assets/common/logo.png';
import { useState } from 'react';
import { useAppSelector } from '@/hooks/useReduxHooks';
import PassportCheckModal from '../sideNavigation/PassportCheckModal';

type navigationType = {
  isTestServer: boolean;
};

function Naviagtion({ isTestServer }: navigationType) {
  const navigate = useNavigate();
  const { isPassportCheck } = useAppSelector((state) => state.validate);
  const [clickedLink, setClickedLink] = useState('');

  const onOpenPassportCheck = (event: any) => {
    event.preventDefault();
    if (isPassportCheck) {
      setClickedLink('/');
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <Container isTestServer={isTestServer}>
        <Logo onClick={onOpenPassportCheck}>
          <Image src={logo} alt='로고.' />
        </Logo>
      </Container>
      <PassportCheckModal
        isOpen={!!clickedLink}
        onClose={() => setClickedLink('')}
        link={clickedLink}
      />
    </>
  );
}

const Container = styled.nav<{ isTestServer: boolean }>`
  height: 80px;
  padding: 28px 32px;
  background-color: ${(props) =>
    props.isTestServer ? 'green' : props.theme.blue[150]};
  width: 200px;
  ${(props) => props.theme.largeView} {
    width: 240px;
  }
`;

const Logo = styled.div`
  cursor: pointer;
`;

const Image = styled.img`
  width: 108px;
  height: 34px;
`;

export default Naviagtion;
