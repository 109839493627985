import React, { useEffect, useRef, useState } from 'react';
import CustomModal from '../common/CustomModal';
import styled from 'styled-components';
import Checkbox from '../common/CheckBox';
import CashBillResultModal from './CashBillResultModal';
import { useMutation } from 'react-query';
import { signUpBarobill } from '@/api/user';

type Props = {
  onClose: () => void;
  onCancel: () => void;
};

function CashBillModal({ onClose, onCancel }: Props) {
  const [isAgreement, setIsAgreement] = useState(false);
  const [agreementResult, setAgreementResult] = useState<
    'success' | 'error' | null
  >(null);
  const [isDisabledCheckbox, setIsDisabledCheckbox] = useState(true);
  const [loading, setLoading] = useState(false);
  const acceptTermsRef = useRef<HTMLDivElement>(null);

  const { mutate: mutateBarobill } = useMutation(signUpBarobill);

  const handleSignUpBarobill = () => {
    setLoading(true);
    mutateBarobill(null, {
      onSuccess: () => {
        setAgreementResult('success');
      },
      onError: () => {
        onClose();
        setLoading(false);
      },
    });
  };

  const handleScroll = () => {
    if (acceptTermsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = acceptTermsRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsDisabledCheckbox(false);
      }
    }
  };

  useEffect(() => {
    const acceptTermsEl = acceptTermsRef.current;
    if (acceptTermsEl) {
      acceptTermsEl.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (acceptTermsEl) {
        acceptTermsEl.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <>
      <CustomModal
        title='환급금 현금영수증 자동 발행 동의'
        desc='환급금 현금영수증 자동 발행을 위한 동의가 필요합니다.'
        contentElement={
          <Content>
            <AcceptTerms ref={acceptTermsRef}>
              <section>
                <h1>제 1 장 총칙</h1>
                <br />
                <br />
                <h2>제 1 조 (목적)</h2>
                <p>
                  이 약관은 석세스모드 (이하 "회사"라 함)가 제공하는 KTP의
                  현금영수증 자동발행 서비스(이하 "서비스"라 함)의 이용 조건 및
                  절차, 회원과 회사의 권리와 의무, 책임사항을 규정하는 것을
                  목적으로 합니다.
                </p>
                <h2>제 2 조 (약관의 효력 및 변경)</h2>
                <p>
                  이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로
                  회원에게 공지함으로써 효력을 발생합니다. 회사는 합당한 사유가
                  발생할 경우 이 약관을 변경할 수 있으며, 변경된 약관은 지체
                  없이 공시합니다.
                </p>
                <h2>제 3 조 (약관 외 준칙)</h2>
                <p>
                  이 약관에 명시되지 않은 사항은 관계법령 및 서비스별 약관의
                  취지에 따라 적용합니다.
                </p>
                <br />
                <br />
                <h1>제 2 장 용어의 정의</h1> <br />
                <br />
                <h2>제 4 조 (용어의 정의)</h2>
                <p>
                  "서비스" 라 함은 석세스모드가 제공하는 KTP의 현금영수증
                  자동발행 서비스를 말합니다. <br />
                  "회원" 이라 함은 회사와 서비스 이용계약을 체결한 자를
                  말합니다. <br />
                  "현금영수증 자동발행" 이라 함은 회사가 바로빌 API를 통해
                  환급액에 대해 자동으로 현금영수증을 발행하는 기능을
                  의미합니다. <br />
                  "바로빌" 이라 함은 현금영수증 발행을 위한 외부 연동 파트너로,
                  회원은 서비스 이용을 위해 바로빌 회원가입이 필요합니다.
                </p>
                <br />
                <br />
                <h1>제 3 장 서비스 이용계약</h1>
                <br />
                <br />
                <h2>제 5 조 (이용계약의 성립)</h2>
                <p>
                  회원이 서비스 가입 시 이 약관에 동의하고 회사가 부여한 ID와
                  비밀번호를 수령한 경우 이용계약이 성립됩니다. <br />
                  기존 바로빌 회원의 경우, 회사는 해당 회원의 기존 아이디를
                  확인하여 본 서비스의 회원사로 추가하며, 이에 대한 동의를
                  받습니다.
                </p>
                <h2>제 6 조 (이용신청)</h2>
                <p>
                  이용신청은 회원이 회사의 온라인 서비스에 접속한 후 회원가입
                  양식을 작성하여 신청합니다. <br />
                  회사가 직접 부여한 ID와 비밀번호를 사용해 가입이 이루어지며,
                  회원은 부여받은 정보로 서비스에 로그인할 수 있습니다. <br />
                  기존 바로빌 회원의 경우, 해당 회원의 기존 아이디를 활용하여 본
                  서비스의 회원으로 추가되며, 이에 대한 동의 절차가 진행됩니다.
                </p>
                <h2>제 7 조 (이용신청의 승낙)</h2>
                <p>
                  회사는 제 6 조의 절차를 거친 이용신청을 승낙합니다. <br />
                  다음 각 호에 해당하는 경우 이용신청을 유보하거나 승낙하지 않을
                  수 있습니다:
                  <ul>
                    <li>- 설비에 여유가 없는 경우</li>
                    <li>- 기술적 문제가 발생한 경우</li>
                    <li>- 기타 회사가 필요하다고 인정하는 경우</li>
                  </ul>
                </p>
                <br />
                <br />
                <h1>제 4 장 서비스 이용</h1>
                <br />
                <br />
                <h2>제 8 조 (회원의 의무)</h2>
                <p>
                  회원은 서비스를 이용할 때 다음과 같은 행위를 하지 않아야
                  합니다:
                  <ul>
                    <li>
                      - 다른 회원의 ID와 비밀번호를 부정하게 사용하는 행위
                    </li>
                    <li>- 제 3자의 저작권 등 권리를 침해하는 행위</li>
                    <li>- 공공질서 및 미풍양속에 반하는 행위</li>
                  </ul>
                  회원은 회사가 공지한 서비스 이용 안내 및 관련 법령을 준수해야
                  합니다.
                </p>
                <h2>제 9 조 (현금영수증 발행)</h2>
                <p>
                  회사는 바로빌 API를 통해 회원의 환급액에 대해 현금영수증을
                  자동으로 발행합니다. <br />
                  서비스를 이용하기 위해 회원은 회사가 부여한 ID와 비밀번호로
                  바로빌 회원가입을 완료해야 합니다. <br />
                  기존 바로빌 회원은 기존 아이디를 회사의 서비스에 연동하며,
                  이에 대한 동의 절차가 필요합니다.
                </p>
                <h2>제 10 조 (서비스 제공 중지)</h2>
                <p>
                  회사는 다음의 사유가 발생한 경우 서비스 제공을 일시 중단할 수
                  있습니다:
                  <ul>
                    <li>- 시스템 유지보수, 교체 등 필요한 경우</li>
                    <li>- 천재지변 등의 불가항력적인 사유가 발생한 경우</li>
                  </ul>
                  회사는 서비스 중단 시 회원에게 사전 통지합니다.
                </p>
                <br />
                <br />
                <h1>제 5 장 계약해지 및 손해배상</h1>
                <br />
                <br />
                <h2>제 11 조 (계약해지 및 이용제한)</h2>
                <p>
                  회원은 언제든지 온라인으로 해지신청을 할 수 있습니다. <br />
                  회사는 다음 각 호에 해당하는 경우 사전 통지 없이 계약을 해지할
                  수 있습니다:
                  <ul>
                    <li>- 타인의 ID와 비밀번호를 도용한 경우</li>
                    <li>- 서비스 운영을 방해한 경우</li>
                    <li>- 기타 약관에 위반된 행위를 한 경우</li>
                  </ul>
                </p>
                <h2>제 12 조 (손해배상)</h2>
                <p>
                  회사는 서비스 제공 중 회원에게 발생한 손해에 대해 책임을 지지
                  않습니다.
                  <br />
                  회사는 천재지변 등의 불가항력으로 인한 서비스 제공 중단에
                  대해서도 책임을 면제받습니다.
                </p>
                <br />
                <br />
                <h1>제 6 장 개인정보 보호</h1>
                <br />
                <br />
                <h2>제 13 조 (개인정보 보호)</h2>
                <p>
                  회사는 회원의 개인정보를 보호하며, 개인정보보호법 등
                  관계법령을 준수합니다.
                  <br />
                  회원의 개인정보는 서비스 제공 및 관리 목적으로만 이용되며,
                  회원의 동의 없이 제 3자에게 제공되지 않습니다.
                </p>
                <br />
                <br />
                <h3>[부칙]</h3>
                <p>이 약관은 2024년 11월 01일부터 시행됩니다.</p>
              </section>
            </AcceptTerms>
            <Checkbox
              id='refund-receipt'
              label='환급금 현금영수증 자동 발행에 동의합니다.'
              isCheck={isAgreement}
              onClick={() => setIsAgreement((prev) => !prev)}
              isDisable={isDisabledCheckbox}
            />
          </Content>
        }
        onClickBtn={handleSignUpBarobill}
        onClickSubBtn={onCancel}
        btnTitle='확인'
        subBtnTitle='닫기'
        btnDisabled={!isAgreement || loading}
      />
      {agreementResult && (
        <CashBillResultModal
          result={agreementResult}
          onClose={() => {
            setAgreementResult(null);
            onClose();
            setLoading(false);
          }}
        />
      )}
    </>
  );
}

const AcceptTerms = styled.p`
  height: 360px;
  overflow-y: auto;
  background-color: #f5f6f7;
  padding: 12px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #5f6165;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #cbccce;
  margin-bottom: 16px;
`;

export default CashBillModal;
