import { getGlobalStatus } from '@/api/user';
import { useQuery } from 'react-query';

const getExpiryWarning = (date: string) => {
  const expiryDate = new Date(date);
  const today = new Date();
  const twoMonthAfter = new Date(today.setMonth(today.getMonth() + 2));
  // 만료일 두 달 전부터 경고
  return expiryDate.getTime() < twoMonthAfter.getTime();
};

const getIsExpired = (date: string) => {
  if (!date) return false;

  const expiryDate = new Date(date);
  const today = new Date();

  return expiryDate.getTime() < today.getTime();
};

function useGetGlobalStatus(pathname?: string) {
  const { data: globalStatus } = useQuery(
    ['getGlobalStatus'],
    getGlobalStatus,
    {
      enabled: !(pathname && pathname === '/kiosk'),
    },
  );
  const isExpiryWarning = getExpiryWarning(
    globalStatus?.promotionExpiryDate || '',
  );
  const isExpired = getIsExpired(globalStatus?.promotionExpiryDate || '');

  return { isExpiryWarning, globalStatus, isExpired };
}

export default useGetGlobalStatus;
