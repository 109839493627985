import React from 'react';
import styled from 'styled-components';

import checkIcon from '@/assets/common/CheckBold.png';
import checkGrayIcon from '@/assets/common/CheckBoldGray.png';
interface IProps {
  id?: string;
  label?: string;
  isDisable?: boolean;
  isCheck: boolean;
  onClick: () => void;
}

function Checkbox({ id, label, isDisable, isCheck = false, onClick }: IProps) {
  return (
    <CheckBoxWrapper>
      <CheckBox
        id={id}
        isCheck={isCheck}
        disabled={isDisable}
        onClick={onClick}
      >
        {!isDisable && (
          <CheckBoxImage src={isCheck ? checkIcon : checkGrayIcon} />
        )}
      </CheckBox>
      {label && <Label htmlFor={id}>{label}</Label>}
    </CheckBoxWrapper>
  );
}

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const CheckBox = styled.button<{ isCheck: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 4px;
  border: ${(props) =>
    props.isCheck ? 'none' : `1px solid ${props.theme.grayColors.scale4}`};
  background-color: ${(props) =>
    props.isCheck ? '#246CF6' : props.theme.pointColors.white};
  :disabled {
    cursor: auto;
    background-color: ${(props) => props.theme.grayColors.scale4};
  }
`;

const CheckBoxImage = styled.img`
  width: 12px;
  height: 8px;
  text-align: center;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #5f6165;
`;

export default Checkbox;
