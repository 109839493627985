import { AxiosError } from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { medicalInfo } from '@/api/medical';
import LoadingView from '@/components/common/LoadingView';
import MedicalAccount from '@/components/medicalInfo/MedicalAccount';
import MedicalInfo from '@/components/medicalInfo/MedicalInfo';
import PasswordChange from '@/components/medicalInfo/PasswordChange';
import MedicalBottomSection from '@/components/medicalInfo/MedicalBottomSection';
import { IMedicalResponse } from '@/types/medical';
import MedicalCashBill from './MedicalCashBill';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';

function MedicalContent() {
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const { globalStatus } = useGetGlobalStatus();

  const { data: medicalData, isLoading } = useQuery<
    IMedicalResponse,
    AxiosError
  >('medical', medicalInfo, {
    retry: false,
    onSuccess: (res) => {},
    onError: (error) => {
      console.log('error', error);
    },
  });

  const onClickChangePassword = () => {
    setIsPasswordChange(true);
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Container>
      {isPasswordChange ? (
        <PasswordChange setIsPasswordChange={setIsPasswordChange} />
      ) : (
        <>
          <SubTitle>의료기관 정보</SubTitle>
          {medicalData && (
            <>
              <MedicalInfo medicalData={medicalData} />
              <MedicalAccount medicalData={medicalData} />
              {globalStatus?.paymentType === 'ORIGINAL' && (
                <MedicalCashBill
                  cashBillAgreementDate={medicalData.cashBillAgreementDate}
                />
              )}
              <MedicalBottomSection
                onClickChangePassword={onClickChangePassword}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 28px;
  padding: 22px 40px 32px 40px;
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
  margin-top: 32px;
`;

export default MedicalContent;
