import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import downArrowIcon from '@/assets/common/downArrow.png';
import upArrowIcon from '@/assets/common/upArrow.png';
import { useAppSelector } from '@/hooks/useReduxHooks';
import FlexWrap from '../common/FlexWrap';
import Text from '../common/Text';
import { formatDotDate } from '@/util/format';
import Button from '../common/Button';
import { INotice } from '@/types/notice';
import { noticeDetailInfo } from '@/api/notice';
import { noticeState } from '@/store/modules/notice';

function NoticeDetail() {
  const dispatch = useDispatch();
  const noticeIndex = new URL(window.location.href).searchParams.get('id');
  const navigate = useNavigate();
  const { currentContent, nextContent, previewContent } = useAppSelector(
    (state) => state.notice,
  );

  const [currentIndex, setCurrentIndex] = useState(noticeIndex);

  const moveToOtherPage = (page: string) => {
    navigate(`/home/notice/detail?id=${page}`);
  };

  const onMoveNext = () => {
    setCurrentIndex(nextContent.noticeIndex);
    moveToOtherPage(nextContent.noticeIndex);
  };

  const onMovePreview = () => {
    setCurrentIndex(previewContent.noticeIndex);
    moveToOtherPage(previewContent.noticeIndex);
  };

  const { refetch } = useQuery<INotice[], AxiosError>(
    'noticeDetail',
    () => noticeDetailInfo(Number(currentIndex)),
    {
      retry: false,
      enabled: false,
      onSuccess: (res) => {
        noticeStateSave(res);
      },
      onError: (error) => {
        console.log('error', error);
      },
    },
  );
  useEffect(() => {
    refetch();
  }, [currentIndex]);

  const noticeStateSave = (notices: INotice[]) => {
    let preview;
    let current;
    let next;

    // 이전 다음 있을때
    if (notices.length === 3) {
      preview = notices[0];
      current = notices[1];
      next = notices[2];
      // 다음글만 있을때
    } else if (Number(currentIndex) === notices[0].noticeIndex) {
      preview = '';
      current = notices[0];
      next = notices[1];
      // 이전글만 있을때
    } else {
      preview = notices[0];
      current = notices[1];
      next = '';
    }
    const payload = {
      preview,
      current,
      next,
    };
    dispatch(noticeState(payload));
  };

  return (
    <Container>
      <TitleSection alignItems='flex-end'>
        <FlexWrap dir='column'>
          <Title>{currentContent.title}</Title>
          <WriterInfo gap='9px'>
            {`작성일   ${
              currentContent.scheduledDate
                ? formatDotDate(currentContent.scheduledDate)
                : ''
            }    |    작성자   ${currentContent.writer}`}
          </WriterInfo>
        </FlexWrap>
        <Button
          text='목록'
          width='240px'
          height='48px'
          color='#3A3B3E'
          borderRadius='8px'
          backgroundColor='#E5E6E8'
          margin='0 0 0 auto'
          fontWeight
          onClick={() => navigate('/home/notice')}
        />
      </TitleSection>

      <ContentContainer
        dangerouslySetInnerHTML={{ __html: currentContent.content }}
      ></ContentContainer>
      <MovePage>
        {previewContent && (
          <MoveItem onClick={onMovePreview}>
            <Image src={upArrowIcon} />
            <Text
              text='이전글'
              fontSize='16px'
              lineHeight='24px'
              fontWeight='500'
              color='#757575'
            />
            <Text
              text={previewContent.title}
              fontSize='16px'
              lineHeight='24px'
              fontWeight='500'
              color='#757575'
              hoverColor='#1856CD'
            />
          </MoveItem>
        )}
        {nextContent && (
          <MoveItem onClick={onMoveNext}>
            <Image src={downArrowIcon} />
            <Text
              text='다음글'
              fontSize='16px'
              lineHeight='24px'
              fontWeight='500'
              color='#757575'
            />
            <Text
              text={nextContent.title}
              fontSize='16px'
              lineHeight='24px'
              fontWeight='500'
              color='#757575'
              hoverColor='#1856CD'
            />
          </MoveItem>
        )}
      </MovePage>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 700px;
  padding: 35px;
  background-color: ${(props) => props.theme.pointColors.white};
  flex: 1;
`;
const TitleSection = styled(FlexWrap)`
  border-bottom: 1px solid ${(props) => props.theme.mono[20]};
  padding-bottom: 32px;
  margin-bottom: 32px;
`;
const Title = styled.h2`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight.large};
`;

const WriterInfo = styled(FlexWrap)`
  white-space: pre-wrap;
  color: ${(props) => props.theme.mono[50]};
`;

const ContentContainer = styled.div`
  flex: 1;
  height: 80%;
  overflow: auto;
`;

const MovePage = styled.div``;

const MoveItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 40px;
  cursor: pointer;
  border-top: 1px solid ${(props) => props.theme.mono[20]};
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
`;

export default NoticeDetail;
